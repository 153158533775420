<template>
  <div>
    <updates />
  </div>
</template>

<script>
import updates from "@/components/admin/updates";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    updates,
  },
  metaInfo: {
    title: "Welkom bij de Flexplek | flexplekken in Nederland ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>
