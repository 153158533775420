<template>
  <div>

    <v-container style="padding-top:4rem">
      <div style="padding-bottom:1rem">
      <v-btn color="secondary" @click="goHome()">
 Ga terug

    </v-btn>
      </div>
      <div >
        <v-row>
          <v-col cols="auto">
            <v-dialog transition="dialog-top-transition" max-width="600">
              
              <template v-slot:activator="{ on, attrs }" >
                <div style="padding-bottom:1rem">
                <v-btn color="primary" v-bind="attrs" v-on="on"
                  >Update toevoegen</v-btn
                >
                      </div>
              </template>
        
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark
                    >Wijzigingen toevoegen</v-toolbar
                  >
                  <v-card-text>
                    <div class="text-h2 pa-12">
                      <v-text-field
                        v-model="title"
                        label="Onderwerp"
                        outlined
                        clearable
                      ></v-text-field>

                      <v-textarea
                        v-model="info"
                        outlined
                        name="input-7-4"
                        label="Omschrijving"
                      ></v-textarea>
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">Sluiten</v-btn>

                    <v-btn text @click="addUpdate()">Opslaan</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
      </div>

      <v-expansion-panels class="mb-6">
        <v-expansion-panel v-for="item in items" :key="item.update_id">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            {{ item.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content
            >{{ item.info }}

            <div class="group">
              <!-- <v-btn class="button" dark small color="green">
                <v-icon>mdi-pencil</v-icon>
              </v-btn> -->
             
                      

              <v-btn
                class="button"
                dark
                small
                color="red"
                @click="deleteUpdate(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>

import api from '../api/api'

import axios from "axios";
export default {

  data() {
    return {
      title: "",
      info: "",
      items: [],
      update: [],
    };
  },
  mounted() {
    api.getUpdates().then((data) => {
      this.items = data;
    });
   
  },
  methods: {
    resetForm() {
      this.title = "";
      this.info = "";
    },
    async addUpdate() {
           try {
        const data = {
       title: this.title,
          info: this.info,
          type: this.kennisuser,
        };

        const response = await api.addUpdate(data);
        console.log(response);

            this.$swal.fire("Dankje!", "Aangemaakt", "success");
      } catch (error) {
        console.error(error);
     this.$swal.fire("Error!", "Er Is een foutmelding.", "error");
      }
    },
    

    async deleteUpdate(item) {
      const result = await this.$swal.fire({
        title: "Ben je zeker?",
        text: "Je kunt dit niet herstellen!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijder",
      });
      if (result.isConfirmed) {
        await axios.delete(
          `https://server.kantoorflex.nl/api/rest/v2/update/${item.update_id}`
        );
        this.$swal.fire("Verwijderd!", "Update is verwijderd.", "success");
        await this.getUpdates();
        this.resetForm();
      }
    },

      
    
  },
  
};
</script>

<style scoped>
.button {
  margin: 5px;
}
.group {
  margin-top: 10px;
  float: right;
}
</style>
